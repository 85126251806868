<template>
  <div class="ps-section__right">
    <div class="ps-section--account-setting">
      <div class="ps-section__content">
        <div
            class="d-sm-flex align-items-center justify-content-between mb-4 mt-4"
        >
          <h3>Addresses</h3>
          <div>
            <router-link :to="{name: 'create_address', params: {address_type: 'billing'}}" class="ps-btn btn-sm">Add Billing Address</router-link>
            &nbsp;&nbsp;
            <router-link :to="{name: 'create_address', params: {address_type: 'shipping'}}" class="ps-btn btn-sm btn-primary">Add Shipping Address</router-link>
          </div>
        </div>

        <div class="table-responsive">
          <table class="table ps-table ps-table--invoices table-bordered">
            <thead class="thead-light">
            <tr>
              <th>Contact Name</th>
              <th>Street</th>
              <th>City</th>
              <th>Country</th>
              <th>Phone Number</th>
              <th>Type</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="address in addresses" :key="address.id">
              <td>{{ address.contact_name }}</td>
              <td>{{address.street}}</td>
              <td>{{ address.city }}</td>
              <td>{{ address.country }}</td>
              <td>{{ address.phone_number }}</td>
              <td>{{ address.type === 1 ? 'Billing' : 'Shipping' }}</td>
              <td><router-link :to="{name: 'edit_address', params: {id: address.id}}"><i class="fa fa-edit" /></router-link></td>
            </tr>
            </tbody>
            <tfoot v-if="!addresses || addresses.length === 0 ">
            <tr>
              <td colspan="7" class="text-center m-4 text-danger">No addresses available</td>
            </tr>
            </tfoot>
          </table>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
// import UserSideNav from '@/components/mechantnavs/UserSideNav.vue';
import global from '@/mixins/global';
import axios from "axios";
import {mapState} from "vuex";

export default {
  mixins:[global],
  data() {
    return {
      loading: true,
      addresses: [],
    };
  },
  mounted() {
    this.getAddresses();
  },
  computed: {
    ...mapState({
      auth: state => state.auth
    }),
  },
  methods: {
    getAddresses() {
      axios.get("address?filter[user]=1").then((response) => {
          this.addresses = response.data;
          this.loading = false;
        }).catch((error) => {
          this.loading = false;
        });
    },
  }
}
</script>

<style>
</style>